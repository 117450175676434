.footer {
  display: flex;
  background-color: #887CC0;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 15px 15px;
  text-align: center;
  color: #fff;
  font-family: serif;
}
.footer a {
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  .footer {
    font-size: 90%;
  }
}
@media screen and (max-width: 375.2px) {
  .footer {
    font-size: 80%;
  }
}/*# sourceMappingURL=footer.css.map */