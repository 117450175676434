.my_carousel_img {
  width: 95%;
  height: 500px;
  border-radius: 10px;
}
@media screen and (max-width: 576px) {
  .my_carousel_img {
    width: 100%;
  }
}

.photo_open {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5725490196);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 6%;
  left: 0;
  width: 100%;
  height: 95vh !important;
  overflow-y: auto;
  z-index: 1;
}
.photo_open img {
  width: 90%;
  height: 150% !important;
  margin-top: 30%;
  border-radius: 7px;
}
@media screen and (max-width: 768px) {
  .photo_open img {
    height: auto !important;
    margin-top: 10%;
  }
}
.photo_open .photo_prev {
  position: absolute;
  background: #887CC0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1.5%;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .photo_open .photo_prev {
    bottom: 10%;
    left: 25%;
  }
}
@media screen and (max-width: 576px) {
  .photo_open .photo_prev {
    bottom: 15%;
  }
}
.photo_open .photo_next {
  position: absolute;
  background: #887CC0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1.5%;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 24px;
  border-radius: 50%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .photo_open .photo_next {
    bottom: 10%;
    right: 25%;
  }
}
@media screen and (max-width: 576px) {
  .photo_open .photo_next {
    bottom: 15%;
  }
}
.photo_open .close_photo {
  position: absolute;
  top: 5%;
  right: 1%;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .photo_open .close_photo {
    right: 5%;
  }
}
@media screen and (max-width: 380px) {
  .photo_open .close_photo {
    top: 10%;
  }
}/*# sourceMappingURL=my_carousel.css.map */