.error{
    padding-top: 150px;
    color: #887CC0;
    text-align: center;
    font-size: 100px;
    user-select: none;
    div{
        color: #000;
        font-size: 48px;
    }
}