#header{
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 60px;
    padding: 0 !important;
    font-weight: 600;
    z-index: 100;
    .navbar-brand{
        display: flex;
        align-items: center;
        width: 80px;
        height: 60px;
        margin: 0;
        img{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
    #nav_toogler{
        background-color: #887CC0 !important;
        box-shadow: none;
    }
    .dropdown-menu{
        background-color: #fff;
        border: none;
        @media screen and (min-width:992px) {
            margin-left: -50px;
        }
    }
    .header_armenian_font{
        @media screen and (min-width: 992px) {
            font-size: 14px;
        }
    }
    .nav_links{
        min-height: 60px;
        a{
            display: flex;
            align-items: center;
            margin: 0 15px;
        }
        @media screen and (max-width:992px) {
            padding: 15px 0;
            a{
                margin: 0;
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width:992px) {
        padding: 15px 0;
    }
    *{
        color: #54595F;
        transition: color 0.5s ease;
        &:hover{
            color: #887CC0;
        }
    }
}
.navbar-collapse.show{
    padding-bottom: 10px !important;
}