#home_photo {
  position: relative;
  font-family: "Montserrat", Sans-serif;
}
#home_photo img {
  width: 100%;
  height: calc(100vh - 60px);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  pointer-events: none;
}
@media screen and (max-width: 992px) {
  #home_photo img {
    height: 500px;
  }
}
@media screen and (max-width: 768px) {
  #home_photo img {
    height: 400px;
  }
}
@media screen and (max-width: 576px) {
  #home_photo img {
    height: 300px;
  }
}
#home_photo #home_photo_title_text {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transform: translateY(-50%);
  animation: home_text 1s forwards;
}
#home_photo #home_photo_title_text #title {
  font-size: 48px;
  font-weight: 500;
}
#home_photo #home_photo_title_text #title span {
  color: #887CC0;
  font-weight: bold;
}
#home_photo #home_photo_title_text #title span:last-child {
  margin-left: 10.5vw;
}
@media screen and (max-width: 768px) {
  #home_photo #home_photo_title_text #title {
    font-size: 32px;
  }
}
@media screen and (max-width: 495px) {
  #home_photo #home_photo_title_text #title {
    font-size: 24px;
  }
}
@media screen and (max-width: 320px) {
  #home_photo #home_photo_title_text #title {
    font-size: 20px;
  }
}
#home_photo #home_photo_title_text #text {
  width: 40%;
  font-size: 20px;
}
@media screen and (max-width: 1200px) {
  #home_photo #home_photo_title_text #text {
    width: 80%;
  }
}
@media screen and (max-width: 992px) {
  #home_photo #home_photo_title_text #text {
    width: 100%;
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  #home_photo #home_photo_title_text #text {
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  #home_photo #home_photo_title_text #text {
    font-size: 12px;
  }
}
@media screen and (max-width: 365px) {
  #home_photo #home_photo_title_text #text {
    font-size: 10px;
  }
}
@media screen and (max-width: 320px) {
  #home_photo #home_photo_title_text #text {
    font-size: 9px;
  }
}
@keyframes home_text {
  from {
    bottom: 50%;
  }
  to {
    bottom: 20%;
  }
}
#home_photo #home_photo_read_more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  animation: home_more 1s forwards;
}
#home_photo #home_photo_read_more span {
  background-color: #887CC0;
  padding: 10px 15px;
  border: 2px solid #887CC0;
  border-radius: 50px;
  color: #fff;
  transition: all 0.5s linear;
  cursor: pointer;
}
#home_photo #home_photo_read_more span:hover {
  background-color: transparent;
}
@media screen and (max-width: 992px) {
  #home_photo #home_photo_read_more {
    display: none;
  }
}
@keyframes home_more {
  from {
    bottom: 0;
  }
  to {
    bottom: 10%;
  }
}

.home_title {
  color: #54595F;
  font-size: 32px;
  font-weight: 600;
}

#contact_us iframe {
  width: 100%;
  height: 400px;
}/*# sourceMappingURL=home.css.map */