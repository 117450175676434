body{
    background-color: #fff;
    margin: 0;
    padding: 0;
    color: #000;
    font-family: "Poppins", Sans-serif;
    &::-webkit-scrollbar{
        background-color: silver;
        width: 15px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: gray;
        border-radius: 15px;
    }
}
.content{
    min-height: calc(100vh - 140px);
}
a{
    text-decoration: none;
}
h2{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.mt-6{
    margin-top: 60px;
}
.mt-10{
    margin-top: 100px;
}
.mt-15{
    margin-top: 150px;
}