.error {
  padding-top: 150px;
  color: #887CC0;
  text-align: center;
  font-size: 100px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.error div {
  color: #000;
  font-size: 48px;
}/*# sourceMappingURL=error.css.map */